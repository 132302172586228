//项目配置可将一些公共的配置放在这个文件中.

import { LanguageList } from "../utils/locale";

// 项目 多语言配置
export const locales = LanguageList.concat([ 'ja', 'ru', 'es']);

// 切换仓位模式  不再提示 localStroge 存储的变量名
export const localChangeContractModeNeverName = 'weex:change:contract:mode';

// 下单时 数量的单位
export const localSwitchContractOrderUnitNeverName = 'weex:switch:contract:order:unit';
export const localSwitchContractOrderNewUnitNeverName = 'weex:switch:newcontract:order:unit';

// 资产页面 隐藏小额资产
export const localHideSmallAsset = 'weex:hide:small:asset';

// 移动委托标记修改提示
export const localMoveModifyNeverName = 'weex:move:modify';

// 开通跟单协议
export const localOpenFollow = 'weex:open:follow';

// 开通跟单协议
export const localOpenCopyFollow = 'weex:open:copy_follow';

// 主站（币币+合约） 混合合约 统一主题
export const SUPPORTED_THEME = ['black', 'white'];

// 显示当前交易币种
export const localShowCurrentCurrency = 'weex:show:currency';

// 交易分离部分页面路径
export const TRADE_PAGE_PATH = [
  'asset',  // 资产模块
  'copytrading',  // 跟单模块
  'copy-trading',  // 跟单模块
  'futures', // 合约交易
  'kline',  // K线
  'record',  // 财务记录模块
  'trade',  // 现货
  'transac',  // 交易记录模块
];

// 搜索引擎UA标识
export const CRAWLER_UA_TOKENS = [
  'Googlebot',
  'FeedFetcher-Google',
  'Google-Read-Aloud',
  'Google Favicon',
  'googleweblight',
  'Storebot-Google',
  'Google-InspectionTool',
  'GoogleOther',
  'Google-Extended',
  'Chrome-Lighthouse',
  'bingbot',
  'MicrosoftPreview',
  'YaDirectFetcher',
  'YandexAccessibilityBot',
  'YandexAdditional',
  'YandexAdNet',
  'YandexBlogs',
  'YandexBot',
  'YandexDirectDyn',
  'YandexFavicons',
  'YandexForDomain',
  'YandexImages',
  'YandexImageResizer',
  'YandexMobileBot',
  'YandexMobileScreenShotBot',
  'YandexNews',
  'YandexOntoDB',
  'YandexPagechecker',
  'YandexSitelinks',
  'YandexVideo',
  'YandexVideoParser',
  'YandexWebmaster',
  'YandexScreenshotBot',
  'YandexMobileBot',
  'YandexRCA',
  'YandexMedia',
  'YandexMetrika',
  'YandexMarket',
  'YandexPagechecker',
  'YandexSpravBot',
  'YandexTurbo',
  'YandexVertis',
  'YandexVerticals',
  'Yeti',
  'Blueno',
  'Daum',
  'DuckDuckBot',
  'Slurp',
  'AhrefsBot',
  'AhrefsSiteAudit',
  'Screaming Frog SEO Spider',
]
